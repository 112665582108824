import React, { useState } from 'react';
import TopNavbar from '../components/top-navbar';
import { Home } from '../components/Home';
import { MainComponentContextType } from '../constants/page-constants';
import { useMainComponent } from '../context/mainComponent.context';
import { Clubs } from '../components/Clubs';
import AboutUs from '../components/About-us';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import { Schedules } from '../components/Schedules';
import Teams from '../components/Teams';

export default function Dashboard() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const component = useMainComponent();

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const closeNav = () => {
    setIsNavOpen(false);
  };

  const renderComponent = () => {
    switch (component?.mainComponent) {
      case MainComponentContextType.ABOUTUS:
        return <AboutUs />;
      case MainComponentContextType.CONTACT:
        return <Contact />;
      case MainComponentContextType.CLUBS:
        return <Clubs />;
      case MainComponentContextType.SCHEDULES:
        return <Schedules />;
      case MainComponentContextType.TEAMS:
        return <Teams />;
      default:
        return <Home />;
    }
  };

  return (
    <>
      <div className="bg-[#21201e] flex flex-col min-h-screen">
        <div className={`z-40 top-0 fixed w-full ${isNavOpen ? 'overflow-hidden' : ''}`}>
          <TopNavbar />
        </div>
        <div className="mt-16"> {/* Adjust this margin if necessary */}
          {renderComponent()}
        </div>
        <Footer />
      </div>
    </>
  );
}
