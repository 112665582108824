import React, { useState } from 'react';
import dynamics_logo from '../assets/dynamics_logo.png';
import { useMainComponent } from '../context/mainComponent.context';
import { MainComponentContextType } from '../constants/page-constants';

const TopNavbar: React.FC = () => {
  const mainComponent = useMainComponent();
  const [isOpen, setIsOpen] = useState(false);

  const handleNavClick = (mainComponentType: MainComponentContextType) => {
    mainComponent?.setMainComponent(mainComponentType);
    setIsOpen(false); 
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <nav className="bg-[#161615] border-[#f9d112] border-b-2">
        <div className="px-10 flex items-center mx-auto p-4 justify-between">
          <a onClick={() => handleNavClick(MainComponentContextType.HOME)} href="#" className="flex items-center space-x-5 pl-4 rtl:space-x-reverse">
            <img src={dynamics_logo} className="h-10 w-10" alt="Flowbite Logo" />
            <span className="font-black_ops_one self-center md:text-2xl text-xl whitespace-nowrap text-[#f9d112]">DYNAMICS 101 MMA</span>
          </a>
          <div className="hidden md:flex md:w-auto">
            <ul className="flex font-medium p-4 md:p-0 mt-4 border rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 bg-[#161615] md:bg-[#161615] border-gray-700">
              <li>
                <a onClick={() => handleNavClick(MainComponentContextType.HOME)} className="hover:text-[#f9d112] block py-2 px-3 text-white rounded md:bg-transparent md:p-0 font-semibold" aria-current="page">Home</a>
              </li>
              <li>
                <a onClick={() => handleNavClick(MainComponentContextType.ABOUTUS)} className="hover:text-[#f9d112] block py-2 px-3 text-white rounded md:bg-transparent md:p-0 font-semibold" aria-current="page">About Us</a>
              </li>
              <li>
                <a onClick={() => handleNavClick(MainComponentContextType.SCHEDULES)} className="hover:text-[#f9d112] block py-2 px-3 text-white rounded md:bg-transparent md:p-0 font-semibold" aria-current="page">Schedules</a>
              </li>
              <li>
                <a onClick={() => handleNavClick(MainComponentContextType.TEAMS)} className="hover:text-[#f9d112] block py-2 px-3 text-white rounded md:bg-transparent md:p-0 font-semibold" aria-current="page">Meet The Team</a>
              </li>
              <li>
                <a onClick={() => handleNavClick(MainComponentContextType.CLUBS)} className="hover:text-[#f9d112] block py-2 px-3 text-white rounded md:bg-transparent md:p-0 font-semibold" aria-current="page">Clubs</a>
              </li>
              <li>
                <a onClick={() => handleNavClick(MainComponentContextType.CONTACT)} className="hover:text-[#f9d112] block py-2 px-3 text-white rounded md:bg-transparent md:p-0 font-semibold" aria-current="page">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      
      {/* Mobile Nav Toggle Button */}
      <button
        className="md:hidden fixed right-4 top-4 z-10 p-2 rounded-lg bg-[#f9d112] text-black"
        onClick={isOpen ? handleClose : handleOpen}
      >
        {isOpen ? 'Close' : 'Explore'}
      </button>
      
      {/* Sidenav */}
      {isOpen && (
        <div className="md:hidden fixed inset-0 bg-black bg-opacity-60 z-20">
          <ul className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <li>
              <a onClick={() => { handleNavClick(MainComponentContextType.HOME); handleClose(); }} className="text-white block py-2 px-3 rounded font-semibold" aria-current="page">Home</a>
            </li>
            <li>
              <a onClick={() => { handleNavClick(MainComponentContextType.ABOUTUS); handleClose(); }} className="text-white block py-2 px-3 rounded font-semibold" aria-current="page">About Us</a>
            </li>
            <li>
              <a onClick={() => { handleNavClick(MainComponentContextType.SCHEDULES); handleClose(); }} className="text-white block py-2 px-3 rounded font-semibold" aria-current="page">Schedules</a>
            </li>
            <li>
              <a onClick={() => { handleNavClick(MainComponentContextType.TEAMS); handleClose(); }} className="text-white block py-2 px-3 rounded font-semibold" aria-current="page">Meet The Team</a>
            </li>
            <li>
              <a onClick={() => { handleNavClick(MainComponentContextType.CLUBS); handleClose(); }} className="text-white block py-2 px-3 rounded font-semibold" aria-current="page">Clubs</a>
            </li>
            <li>
              <a onClick={() => { handleNavClick(MainComponentContextType.CONTACT); handleClose(); }} className="text-white block py-2 px-3 rounded font-semibold" aria-current="page">Contact Us</a>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default TopNavbar;
